@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 255 255 255;

    --foreground: 0 0 0;

    --muted: 222 226 230;

    --muted-foreground: 134 142 150;

    --popover: 255 255 255;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 255 255 255;

    --border: 222 226 230;
    --input: 222 226 230;

    --primary: var(--run);
    --primary-foreground: var(--run-foreground);

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --run: 221.2 83.2% 53.3%;
    --run-foreground: 210 40% 98%;

    --ride: 24.6 95% 53.1%;
    --ride-foreground: 60 9.1% 97.8%;
  }

  [data-theme='dark'] {
    /* Mantine dark-8 */
    --background: 20 21 23;

    /* Mantine dark-0 */
    --foreground: 193 194 197;

    /* Mantine dark-7 */
    --muted: 37 38 43;

    /* Mantine dark-2 */
    --muted-foreground: 144 146 150;

    /* Mantine dark-7 */
    --popover: 20 21 23;
    --popover-foreground: 215 20.2% 65.1%;

    /* Mantine dark-8 */
    --card: 20 21 23;

    /* Mantine dark-4 */
    --border: 55 58 64;
    --input: 55 58 64;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;
  }
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  [data-activity-type='ride'] {
    --primary: var(--ride);
    --primary-foreground: var(--ride-foreground);
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
  [data-mdx-container] {
    .anchor {
      @apply invisible absolute -ml-8 w-full cursor-pointer !no-underline;
    }

    .anchor:after {
      content: '🔗';
    }

    :hover > .anchor {
      @apply visible;
    }
  }
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }
  .step {
    counter-increment: step;
    /* @apply sticky top-16; */
  }

  .step:before {
    @apply sticky top-16 w-9 h-9 bg-muted rounded-full font-mono font-medium text-center text-base inline-flex items-center justify-center -indent-px border-4 border-background;
    @apply ml-[-50px] my-auto;
    content: counter(step);
  }
}

@layer mapbox {
  .maplibregl-popup-content {
    @apply !bg-popover !border !rounded-md !px-2 !py-0;
  }
  .mapboxgl-popup-anchor-bottom > .maplibregl-popup-tip {
    @apply !border-t-popover;
  }
  .maplibregl-popup-anchor-top > .maplibregl-popup-tip {
    @apply !border-b-popover;
  }

  .mapboxgl-ctrl-group button,
  .maplibregl-ctrl-group button span {
    @apply !bg-muted !border !rounded-md !text-foreground;
  }
}
@layer content {
  [data-youtube-video].ProseMirror-selectednode {
    @apply !outline rounded-lg;
  }
}
